// src/components/EmailChangeCancel.js

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";

const EmailChangeCancel = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("Processing your request...");
  const [error, setError] = useState("");
  const axiosInstance = useContext(AxiosContext);

  useEffect(() => {
    cancelEmailChange();
  }, []);

  const cancelEmailChange = async () => {
    try {
      const response = await axiosInstance.get(`/email-change-cancel/${token}/`);
      setMessage("Your email change request has been canceled. If this wasn't you, please secure your account.");
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message || "Failed to cancel email change.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="main-content">
      <div className="email-change-cancel-container">
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="success-message">{message}</div>
        )}
      </div>
    </div>
  );
};

export default EmailChangeCancel;