// ParentDoodlePreview.js

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDown,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import ImageWithLoader from "../components/ImageWithLoader";

const ParentDoodlePreview = ({
  isReactionTo,
  parentReaction,
  openReactionsModal,
}) => {
  // If we do not have at least one post (either initial or parent), render nothing.
  if (!isReactionTo && !parentReaction) {
    return null;
  }

  // We define two variables: initialDoodle (case 1) and secondaryDoodle (case 2).
  // initialDoodle: The primary post to display.
  // secondaryDoodle: The second post (parent reaction), if available.
  let initialDoodle = isReactionTo;
  let secondaryDoodle = parentReaction;

  // If the initial post (isReactionTo) is missing but we have a parentReaction,
  // treat the parentReaction as the initial post.
  if (!initialDoodle && secondaryDoodle) {
    // The original post no longer exists, we fallback to parentReaction as the primary post.
    initialDoodle = secondaryDoodle;
    secondaryDoodle = null;
  }

  // Now we determine the "case":
  // caseOne: Only one doodle to show.
  // caseTwo: Two doodles to show (if secondaryDoodle exists).
  const caseOne = !secondaryDoodle;
  const caseTwo = !!secondaryDoodle;

  const handleThumbnailClick = (doodle) => {
    if (openReactionsModal) {
      openReactionsModal(doodle);
    }
  };

  return (
    <div className="parent-doodle-preview-container">
      <div className="parent-doodle-preview">
        {caseOne && initialDoodle && (
          <div className="case-one">
            {/* Initial doodle block */}
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(initialDoodle)}
            >
              <Link
                to={`/${initialDoodle.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()}
              >
                {initialDoodle.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={initialDoodle.thumbnail}
                  alt={`${initialDoodle.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
              <FontAwesomeIcon
                icon={faArrowTurnDown}
                className="icon-turn-down"
              />
            </div>
          </div>
        )}

        {caseTwo && initialDoodle && secondaryDoodle && (
          <div className="case-two">
            {/* Initial post */}
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(initialDoodle)}
            >
              <Link
                to={`/${initialDoodle.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()}
              >
                {initialDoodle.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={initialDoodle.thumbnail}
                  alt={`${initialDoodle.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
            </div>

            <FontAwesomeIcon icon={faArrowRightLong} className="icon-right" />

            {/* Parent reaction post */}
            <div
              className="thumbnail-container"
              onClick={() => handleThumbnailClick(secondaryDoodle)}
            >
              <Link
                to={`/${secondaryDoodle.belongs_to_user.username}`}
                className="username"
                onClick={(e) => e.stopPropagation()}
              >
                {secondaryDoodle.belongs_to_user.username}
              </Link>
              <div className="image-wrapper">
                <ImageWithLoader
                  src={secondaryDoodle.thumbnail}
                  alt={`${secondaryDoodle.belongs_to_user.username}'s post`}
                  containerClassName="image-loader-container"
                  imageClassName="thumbnail"
                />
              </div>
              <FontAwesomeIcon
                icon={faArrowTurnDown}
                className="icon-turn-down"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentDoodlePreview;
