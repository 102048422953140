// Post.js

import React from "react";
import Doodle from "./Doodle";
import ReactionsPreview from "./ReactionsPreview";
import { useDoodleContext } from "../components/DoodleContext";
import ParentDoodlePreviews from "./ParentDoodlePreviews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const Post = ({
  doodle,
  isViewingOwnWall,
  feedType,
  handleOpenDoodleEditor,
  onFollowChange,
}) => {
  const { openReactionsModal } = useDoodleContext();

  const isReaction = doodle.doodle_purpose === 2;

  return (
    <>
      {isReaction && (
        <ParentDoodlePreviews
          isReactionTo={doodle.is_reaction_to}
          parentReaction={doodle.parent_reaction}
          openReactionsModal={openReactionsModal}
        />
      )}
      <div className="post" key={doodle.id}>
        {isReaction && (
          <div className="repost-info">
            <FontAwesomeIcon icon={faComment} className="icon" />
            reacted
          </div>
        )}
        <Doodle
          doodle={doodle}
          isViewingOwnWall={isViewingOwnWall}
          feedType={feedType}
          handleOpenDoodleEditor={handleOpenDoodleEditor}
          typeOfDoodle={"doodles"}
          onFollowChange={onFollowChange}
        />
        {doodle.reactions && doodle.reactions.length > 0 && (
          <>
            <hr className="separator" />
            <ReactionsPreview
              doodle={doodle}
              openReactionsModal={openReactionsModal}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Post;
