// DrawingArea.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stage, Layer, Line, Circle, Image as KonvaImage } from "react-konva";
import useImage from "use-image";

const DrawingArea = ({
  lines,
  setLines,
  selectedTool,
  selectedColor,
  config,
  colorQuantities,
  setColorQuantities,
  colorSpendFactor,
  exactQuantities,
  changedPixels,
  allChangedPixels,
  colorHistory,
  setColorHistory,
  toolDurabilities,
  setToolDurabilities,
  durabilitySpendFactor,
  exactDurabilities,
  toolHistory,
  setToolHistory,
  isDrawing,
  setIsDrawing,
  brushTextureSrc,
  existingDoodleImage,
  editorContext,
  clearRedoStacks, // Function to clear redo stacks
}) => {
  const [brushTexture] = useImage(brushTextureSrc);
  const [backgroundImage] = useImage(existingDoodleImage);
  const stageRef = useRef(null);

  // Check if the editorContext indicates that we're drawing an avatar
  const isAvatarEditor = editorContext?.is_avatars;

  // Define handleEnd before handleMove to avoid reference error
  const handleEnd = useCallback(() => {
    if (!isDrawing) return;
    setIsDrawing(false);
    const lastLine = lines[lines.length - 1];
    if (!lastLine) return;

    const colorKey = lastLine.colorKey;
    const toolKey = lastLine.tool;

    // Save new changedPixels and allChangedPixels state for the color
    const newChangedPixels = new Set(changedPixels.current[colorKey]);
    const newAllChangedPixels = new Set(allChangedPixels.current[colorKey]);

    // Track color usage
    if (exactQuantities.current[colorKey] !== Infinity) {
      const colorUsed =
        lastLine.initialQuantity - exactQuantities.current[colorKey];
      setColorHistory((prevHistory) => ({
        ...prevHistory,
        [colorKey]: [
          ...(prevHistory[colorKey] || []),
          {
            colorKey,
            colorUsed,
            initialQuantity: lastLine.initialQuantity,
            newExactQuantity: exactQuantities.current[colorKey],
            previousChangedPixels: lastLine.previousChangedPixels,
            newChangedPixels,
            previousAllChangedPixels: lastLine.previousAllChangedPixels,
            newAllChangedPixels,
          },
        ],
      }));
    }

    // Track durability usage
    if (exactDurabilities.current[toolKey] !== Infinity) {
      const durabilityUsed =
        lastLine.initialDurability - exactDurabilities.current[toolKey];
      setToolHistory((prevHistory) => ({
        ...prevHistory,
        [toolKey]: [
          ...(prevHistory[toolKey] || []),
          {
            toolKey,
            durabilityUsed,
            initialDurability: lastLine.initialDurability,
            newExactDurability: exactDurabilities.current[toolKey],
          },
        ],
      }));
    }
  }, [
    isDrawing,
    lines,
    setColorHistory,
    setToolHistory,
    exactQuantities,
    exactDurabilities,
    changedPixels,
    allChangedPixels,
    setIsDrawing,
  ]);

  const handleMove = useCallback(
    (e) => {
      if (!isDrawing) return;
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      if (!point) return;

      setLines((prevLines) => {
        const lastLine = prevLines[prevLines.length - 1];
        if (!lastLine) return prevLines;

        // Append new point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // Update color quantity and tool durability
        const newPixels = trackChangedPixels(
          lastLine.points,
          lastLine.thickness
        );
        const colorKey = lastLine.colorKey;
        const toolKey = lastLine.tool;

        let colorSpent = false;
        let toolSpent = false;

        // Handle color spend
        if (exactQuantities.current[colorKey] !== Infinity) {
          newPixels.forEach((pixel) => {
            if (!allChangedPixels.current[colorKey].has(pixel)) {
              allChangedPixels.current[colorKey].add(pixel);
              changedPixels.current[colorKey].add(pixel);
              exactQuantities.current[colorKey] -= colorSpendFactor;
            }
          });

          // Prevent negative quantities
          if (exactQuantities.current[colorKey] < 0) {
            exactQuantities.current[colorKey] = 0;
          }

          setColorQuantities((prevQuantities) => ({
            ...prevQuantities,
            [colorKey]: Math.round(exactQuantities.current[colorKey]),
          }));
          lastLine.pixelCount += newPixels.size;

          if (exactQuantities.current[colorKey] === 0) {
            colorSpent = true;
          }
        }

        // Handle durability spend
        if (exactDurabilities.current[toolKey] !== Infinity) {
          if (lastLine.points.length >= 4) {
            const distance = calculateDistance(
              [
                lastLine.points[lastLine.points.length - 4],
                lastLine.points[lastLine.points.length - 3],
              ],
              [point.x, point.y]
            );
            const durabilityUsed = distance * durabilitySpendFactor;
            lastLine.durabilitySpend += durabilityUsed;
            exactDurabilities.current[toolKey] -= durabilityUsed;

            // Prevent negative durabilities
            if (exactDurabilities.current[toolKey] < 0) {
              exactDurabilities.current[toolKey] = 0;
            }

            setToolDurabilities((prevDurabilities) => ({
              ...prevDurabilities,
              [toolKey]: Math.round(exactDurabilities.current[toolKey]),
            }));

            if (exactDurabilities.current[toolKey] === 0) {
              toolSpent = true;
            }
          }
        }

        // If either color or tool has been spent, terminate the stroke
        if (colorSpent || toolSpent) {
          setIsDrawing(false);
          handleEnd(); // Finalize the stroke and save history
        }

        return [...prevLines];
      });
    },
    [
      isDrawing,
      durabilitySpendFactor,
      colorSpendFactor,
      setLines,
      exactQuantities,
      exactDurabilities,
      allChangedPixels,
      changedPixels,
      setIsDrawing,
      handleEnd,
      setColorQuantities,
      setToolDurabilities,
    ]
  );

  const handleStart = useCallback(
    (e) => {
      const tool = config.tools[selectedTool];
      let colorKey;

      clearRedoStacks(); // Clear redo stacks on new action

      if (!tool.has_palette) {
        colorKey = tool.default_color.color;
      } else {
        colorKey = Object.keys(config.colors).find(
          (key) => config.colors[key].color === selectedColor
        );
      }

      if (
        colorQuantities[colorKey] === 0 ||
        toolDurabilities[selectedTool] === 0
      ) {
        return; // Prevent starting a new stroke if the color quantity or tool durability is zero
      }

      setIsDrawing(true);
      const { x, y } = e.target.getStage().getPointerPosition();
      const thickness = tool.thickness;

      // Save previous changedPixels and allChangedPixels state for the color
      const previousChangedPixels = new Set(changedPixels.current[colorKey]);
      const previousAllChangedPixels = new Set(
        allChangedPixels.current[colorKey]
      );

      // Initialize a new line with history tracking
      setLines((prevLines) => [
        ...prevLines,
        {
          tool: selectedTool,
          points: [x, y],
          stroke: selectedColor,
          strokeWidth: thickness,
          strokePatternImage: selectedTool === "textured" ? brushTexture : null,
          colorKey,
          thickness,
          initialQuantity: exactQuantities.current[colorKey],
          initialDurability: exactDurabilities.current[selectedTool],
          pixelCount: 0,
          durabilitySpend: 0,
          previousChangedPixels, // Add previousChangedPixels to the line
          previousAllChangedPixels, // Add previousAllChangedPixels to the line
        },
      ]);
    },
    [
      config,
      selectedTool,
      selectedColor,
      colorQuantities,
      toolDurabilities,
      exactQuantities,
      exactDurabilities,
      brushTexture,
      setLines,
      setIsDrawing,
      clearRedoStacks,
      changedPixels,
      allChangedPixels,
    ]
  );

  const trackChangedPixels = (points, thickness) => {
    const newPixels = new Set();
    for (let i = 0; i < points.length - 2; i += 2) {
      const x = points[i];
      const y = points[i + 1];
      for (
        let dx = -Math.floor(thickness / 2);
        dx <= Math.floor(thickness / 2);
        dx++
      ) {
        for (
          let dy = -Math.floor(thickness / 2);
          dy <= Math.floor(thickness / 2);
          dy++
        ) {
          newPixels.add(`${Math.round(x + dx)},${Math.round(y + dy)}`);
        }
      }
    }
    return newPixels;
  };

  const calculateDistance = (pointA, pointB) => {
    const [x1, y1] = pointA;
    const [x2, y2] = pointB;
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  useEffect(() => {
    const handleWindowMouseUp = () => {
      if (isDrawing) {
        handleEnd();
      }
    };

    window.addEventListener("mouseup", handleWindowMouseUp);
    window.addEventListener("touchend", handleWindowMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleWindowMouseUp);
      window.removeEventListener("touchend", handleWindowMouseUp);
    };
  }, [isDrawing, handleEnd]);

  return (
    <Stage
      width={800}
      height={600}
      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
      ref={stageRef}
    >
      <Layer>
        {backgroundImage && (
          <KonvaImage
            image={backgroundImage}
            x={0}
            y={0}
            width={800}
            height={600}
          />
        )}
        {lines.map((line, i) => (
          <Line
            key={i}
            points={line.points}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
            lineCap="round"
            lineJoin="round"
            globalCompositeOperation={
              line.tool === "eraser" ? "destination-out" : "source-over"
            }
            strokePatternImage={
              line.tool === "textured" && brushTexture ? brushTexture : null
            }
            strokePatternScale={{
              x: 0.1,
              y: 0.1,
            }}
          />
        ))}
        {/* Add the semi-transparent mask when drawing an avatar */}
        {isAvatarEditor && (
          <Circle x={400} y={300} radius={300} stroke="black" strokeWidth={2} />
        )}
      </Layer>
    </Stage>
  );
};

export default DrawingArea;
