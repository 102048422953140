import React, { useState } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import UserAvatar from "./UserAvatar";
import { useUserContext } from "../components/UserContext";
import CustomContextMenu from "./CustomContextMenu";

const Message = ({ message, companionUsername, companionAvatar, onClose }) => {
  const { userAvatar, userId, userName } = useUserContext();
  const isCurrentUser = message.belongs_to_user.id === userId;

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div
      className={`message ${
        isCurrentUser ? "outgoing" : "incoming"
      } message-enter`}
    >
      {message.belongs_to_user.id !== userId ? (
        <Link to={`/${companionUsername}`} onClick={onClose}>
          <UserAvatar
            avatarUrl={companionAvatar}
            username={companionUsername}
          />
        </Link>
      ) : (
        <Link to={`/${userName}`} onClick={onClose}>
          <UserAvatar avatarUrl={userAvatar.thumbnail} username={userName} />
        </Link>
      )}
      <div className={`message-content ${isImageLoaded ? "" : "loading"}`}>
        <img
          src={message.image}
          alt="Message"
          className={`message-image ${isImageLoaded ? "loaded" : ""}`}
          onLoad={handleImageLoad}
        />
        {isImageLoaded && (
          <span className="message-time">
            {new Date(message.created).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        )}
        {message.belongs_to_user.id === userId && (
          <CustomContextMenu>
            <Delete doodle={message} typeOfDoodle={"messages"} />
          </CustomContextMenu>
        )}
      </div>
    </div>
  );
};

export default Message;
