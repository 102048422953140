import React, { useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";

const SetNewPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const axiosInstance = useContext(AxiosContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const response = await axiosInstance.post("/password-reset-confirm/", {
        uid,
        token,
        password,
      });
      setMessage("Your password has been reset successfully.");
      setTimeout(() => navigate("/signin"), 3000);
    } catch (err) {
      if (err.response && err.response.data) {
        // Handle error messages from the server
        setError(err.response.data.error || "Failed to reset password.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="main-content">
      <div className="set-new-password-container">
        {message ? (
          <div className="success-message">{message}</div>
        ) : (
          <form onSubmit={handleSubmit} className="set-new-password-form">
            {error && <div className="error-message">{error}</div>}
            <input
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="submit" className="set-new-password-button">
              Set New Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetNewPasswordForm;
