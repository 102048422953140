import React from "react";
import UserAvatar from "./UserAvatar";
import { useUserContext } from "../components/UserContext";
import { useGlobalState } from "../contexts/GlobalStateContext";

function UserMeta({
  userName,
  avatar,
  followsCount,
  followersCount,
  openOverlay,
  userLevel,
  userScore,
  levelCompletionPercent,
  toggleProgressOverlay,
}) {
  const { ShouldShowSignUpPrompt } = useUserContext();
  const { isSideMenuOpen, closeSideMenu } = useGlobalState();

  const closeSideMenuIfOpened = () => {
    if (isSideMenuOpen) {
      closeSideMenu();
    }
  };

  const handleProgressBarClick = (event) => {
    toggleProgressOverlay();
    closeSideMenuIfOpened();
  };

  const handleOverlayClick = (overlayType, event) => {
    if (ShouldShowSignUpPrompt()) {
      return; // Block action if sign-up prompt should be shown
    }
    openOverlay(overlayType);
    closeSideMenuIfOpened();
  };

  return (
    <div className="main-user-info">
      <UserAvatar
        avatarUrl={avatar}
        username={userName}
        userLevel={userLevel}
      />
      {userScore && (
        <div
          className="progress-bar-container"
          onClick={handleProgressBarClick}
        >
          <div
            className="progress-bar"
            style={{ width: `${levelCompletionPercent}%` }}
          ></div>
          <div className="progress-bar-text">{`${levelCompletionPercent}%`}</div>
        </div>
      )}
      {userName && <div className="user-name">{userName}</div>}
      <div className="account-info">
        <span onClick={(event) => handleOverlayClick("follows", event)}>
          Follows: {followsCount}
        </span>
        <span> | </span>
        <span onClick={(event) => handleOverlayClick("followers", event)}>
          Followers: {followersCount}
        </span>
      </div>
    </div>
  );
}

export default UserMeta;
