import React, { useState, useContext } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const axiosInstance = useContext(AxiosContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/password-reset/", { email });
      setMessage("Password reset link has been sent to your email.");
    } catch (err) {
      if (err.response && err.response.data) {
        // Handle error messages from the server
        setError(
          err.response.data.error || "Failed to send password reset email."
        );
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="main-content">
      <div className="reset-password-container">
        {message ? (
          <div className="success-message">{message}</div>
        ) : (
          <form onSubmit={handleSubmit} className="reset-password-form">
            {error && <div className="error-message">{error}</div>}
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="reset-password-button">
              Reset Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
