// hooks/useIsViewingOwnWall.js

import { useLocation } from "react-router-dom";
import { useUserContext } from "../components/UserContext";

const useIsViewingOwnWall = () => {
  const location = useLocation();
  const { userName } = useUserContext();

  const getPathUsername = () => {
    const match = location.pathname.match(/^\/([^/]+)/);
    if (match) {
      return match[1];
    }
    return null;
  };

  const pathUsername = getPathUsername();
  return pathUsername === userName;
};

export default useIsViewingOwnWall;