import React, { useState, useEffect, useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { useLocation, useParams } from "react-router-dom";
import AddProfileEntry from "../components/AddProfileEntry";
import ProfileEntriesFeed from "../components/ProfileEntriesFeed";
import { useDoodleContext } from "../components/DoodleContext";
import Loader from "../components/Loader";
import ClosableOverlay from "../components/ClosableOverlay";
import ImageWithLoader from "../components/ImageWithLoader";
import { RemoveScroll } from "react-remove-scroll";
import ReactDOM from "react-dom";

const Profile = ({ userName, handleOpenDoodleEditor }) => {
  const axiosInstance = useContext(AxiosContext);
  const [loading, setLoading] = useState(true);

  const {
    usernameForProfilePage,
    setUsernameForProfilePage,
    profileEntriesPage,
    setProfileEntriesPage,
    isProfileEntriesFeedOpen,
    profileEntryTypeObject,
    setProfileEntryTypeObject,
    setIsProfileEntriesFeedOpen,
  } = useDoodleContext();

  const { pathUsername } = useParams();
  const location = useLocation();
  const isViewingOwnProfile =
    location.pathname === "/profile" || pathUsername === userName;

  useEffect(() => {
    const fetchProfileEntries = async () => {
      const usernameToFetch = isViewingOwnProfile ? userName : pathUsername;
      if (usernameToFetch) {
        try {
          const response = await axiosInstance.get(
            `/profile/${usernameToFetch}`
          );
          setProfileEntriesPage(response.data);
          setUsernameForProfilePage(usernameToFetch);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching profile entries:", error);
        }
      }
    };

    fetchProfileEntries();
  }, [isViewingOwnProfile, userName, pathUsername]);

  const handleOpenProfileEntriesFeed = (entryTypeObject) => {
    setProfileEntryTypeObject(entryTypeObject);
    setIsProfileEntriesFeedOpen(true);
  };

  const handleCloseProfileEntriesFeed = () => {
    setIsProfileEntriesFeedOpen(false);
  };

  if (loading) {
    return (
      <div className="main-content">
        <Loader />
      </div>
    ); // Render a loader component while data is being fetched
  }

  return (
    <div className="main-content">
      {isProfileEntriesFeedOpen &&
        ReactDOM.createPortal(
          <RemoveScroll>
            <ClosableOverlay
              onClose={handleCloseProfileEntriesFeed}
              headerContent={profileEntryTypeObject.label}
            >
              <ProfileEntriesFeed
                isViewingOwnProfile={isViewingOwnProfile}
                handleOpenDoodleEditor={handleOpenDoodleEditor}
              />
            </ClosableOverlay>
          </RemoveScroll>,
          document.getElementById("modal-root")
        )}
      <div className="profile-page">
        <h1>{usernameForProfilePage}'s bio</h1>
        {profileEntriesPage.map((entryType) => (
          <div className="entry-type" key={entryType.id}>
            <div className="entry-type__header">
              <h2>{entryType.label}</h2>
              {isViewingOwnProfile && (
                <AddProfileEntry
                  entryType={entryType}
                  handleOpenDoodleEditor={handleOpenDoodleEditor}
                  isViewingOwnProfile={isViewingOwnProfile}
                />
              )}
            </div>
            <div
              className="entry-type__content"
              onClick={() => handleOpenProfileEntriesFeed(entryType)}
            >
              {entryType.doodles &&
                entryType.doodles.length > 0 &&
                entryType.doodles.map((doodle) => (
                  <div className="entry" key={doodle.id}>
                    <ImageWithLoader
                      src={doodle.image}
                      alt={`Profile entry ${doodle.id}`}
                      containerClassName="thumbnail-container"
                    />
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
