import React, { useState, useRef, useEffect, useCallback } from "react";
import Message from "./Message";
import UserAvatar from "./UserAvatar";
import SendMessageToChat from "./SendMessageToChat";
import { useDoodleContext } from "../components/DoodleContext";
import { Link } from "react-router-dom";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import ClosableOverlay from "../components/ClosableOverlay";

const ChatOverlay = ({
  chatId,
  userName,
  onClose,
  handleOpenDoodleEditor,
  companionUsername,
  companionAvatar,
}) => {
  const { items, loadMore, hasMore, loading, error } = usePagination(
    `/chats/${chatId}/`,
    20,
    true
  );

  const [isDebouncing, setIsDebouncing] = useState(false);
  const chatContentRef = useRef(null);
  const { chatMessages, setChatMessages } = useDoodleContext();
  const messagesEndRef = useRef(null);
  const initialLoad = useRef(true);
  const previousScrollHeightRef = useRef(0);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 2000);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  const scrollToBottom = (behavior = "smooth") => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };

  const handleNewMessage = () => {
    scrollToBottom();
  };

  const handleLoadMore = () => {
    previousScrollHeightRef.current = chatContentRef.current.scrollHeight;
    debouncedLoadMore();
  };

  useEffect(() => {
    if (!error) {
      setChatMessages(items);
      if (initialLoad.current) {
        scrollToBottom("auto");
        initialLoad.current = false;
      }
    }
  }, [items, setChatMessages, error]);

  useEffect(() => {
    if (chatMessages.length > 0) {
      const currentScrollHeight = chatContentRef.current.scrollHeight;
      const scrollDifference =
        currentScrollHeight - previousScrollHeightRef.current;
      if (scrollDifference > 0) {
        chatContentRef.current.scrollTop += scrollDifference;
      }
    }
  }, [chatMessages]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      handleLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  const headerContent = (
    <>
      <Link
        to={`/${companionUsername}`}
        className="user-avatar-container"
        onClick={onClose}
      >
        <UserAvatar avatarUrl={companionAvatar} username={companionUsername} />
      </Link>
      <div className="user-name">
        <Link to={`/${companionUsername}`} onClick={onClose}>
          {companionUsername}
        </Link>
      </div>
    </>
  );

  return (
    <ClosableOverlay
      onClose={onClose}
      headerContent={headerContent}
      className="chat-overlay"
      contentRef={chatContentRef}
    >
      <div className="chat-content" ref={chatContentRef}>
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <>
            <div ref={ref}></div>
            {chatMessages.map((message, index) => (
              <Message
                key={index}
                message={message}
                userName={userName}
                companionUsername={companionUsername}
                companionAvatar={companionAvatar}
                onClose={onClose}
              />
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>
      <SendMessageToChat
        chatId={chatId}
        handleOpenDoodleEditor={handleOpenDoodleEditor}
      />
    </ClosableOverlay>
  );
};

export default ChatOverlay;
