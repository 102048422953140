import React, { useEffect, useState } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

function TelegramWebApp() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    let initDataRaw = null;
    try {
      const params = retrieveLaunchParams();
      initDataRaw = params.initDataRaw;
    } catch (err) {
      console.warn("retrieveLaunchParams() failed:", err);
      // Fallback: try accessing window.Telegram.WebApp.initDataRaw directly
      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.initDataRaw
      ) {
        initDataRaw = window.Telegram.WebApp.initDataRaw;
      } else {
        initDataRaw = null;
      }
    }

    setInitData(initDataRaw);

    if (!initDataRaw) {
      // If we still don't have initDataRaw, handle gracefully:
      setError(
        "No init data found. Please open this WebApp inside Telegram for a proper experience."
      );
      return; // Stop execution here, as we can't verify without initData.
    }

    // Send initDataRaw to backend for verification
    fetch("/api/telegram/verify-initdata/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ initData: initDataRaw }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setUser(data.user);
        } else {
          setError(`Backend error: ${data.error}`);
        }
      })
      .catch((err) => {
        setError(`Fetch error: ${err.message}`);
      });
  }, []);

  if (error) {
    return (
      <div style={{ color: "red" }}>
        <h2>Error:</h2>
        <p>{error}</p>
        {initData && (
          <div>
            <h3>initData (raw):</h3>
            <pre style={{ whiteSpace: "pre-wrap" }}>{initData}</pre>
          </div>
        )}
      </div>
    );
  }

  if (!user) {
    return (
      <div>
        <p>Loading your Cavegram profile...</p>
        {initData && (
          <div>
            <h3>initData (raw):</h3>
            <pre style={{ whiteSpace: "pre-wrap" }}>{initData}</pre>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <h1>Welcome, {user.username}!</h1>
      {/* Show user feed, etc. */}
    </div>
  );
}

export default TelegramWebApp;
