// src/components/SideMenu.js

import React from "react";
import { Link, useLocation } from "react-router-dom";
import UserMeta from "./UserMeta";
import SignOut from "./SignOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHouse,
  faBell,
  faMessage,
  faRightFromBracket,
  faRightToBracket,
  faClone,
  faHeartbeat,
  faGear,
  faBurst,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from "../contexts/NotificationsContext";
import { useUpdates } from "../contexts/UpdatesContext";
import { useUserContext } from "../components/UserContext";

const SideMenu = ({
  isSideMenuOpen,
  toggleSideMenu,
  menuRef,
  userName,
  userAvatar,
  userFollows,
  userFollowers,
  openOverlay,
  setIsAuthenticated,
  toggleProgressOverlay,
}) => {
  const location = useLocation();
  const { notifications } = useNotifications();
  const { updates } = useUpdates();
  const { isAuthenticated } = useUserContext();

  // Define paths where the SideMenu should be hidden
  const hideMenuPaths = ["/signin", "/signup"];

  // Check if the current path starts with any of the hideMenuPaths
  const shouldHideMenu = hideMenuPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  if (shouldHideMenu || isAuthenticated === null) {
    return null; // Do not render SideMenu on /signin and /signup pages
  }

  return (
    <aside
      ref={menuRef}
      className={`menu-screen ${isSideMenuOpen ? "show-menu" : ""}`}
      aria-hidden={!isSideMenuOpen} // Accessibility: hide from screen readers when not open
    >
      {isAuthenticated ? (
        <>
          <UserMeta
            userName={userName}
            avatar={userAvatar?.thumbnail}
            followsCount={userFollows}
            followersCount={userFollowers}
            openOverlay={openOverlay}
            userLevel={updates.newLevel}
            userScore={updates.newScore}
            levelCompletionPercent={updates.newLevelCompletionPercent}
            toggleProgressOverlay={toggleProgressOverlay}
          />
          <div className="menu-links">
            <Link to="/" aria-label="Feed" onClick={toggleSideMenu}>
              <FontAwesomeIcon icon={faHouse} />
              Feed
            </Link>
            <Link to="/profile" aria-label="Bio" onClick={toggleSideMenu}>
              <FontAwesomeIcon icon={faAddressCard} />
              Bio
            </Link>
            <Link
              to={`/${userName}`}
              aria-label="Wall"
              onClick={toggleSideMenu}
            >
              <FontAwesomeIcon icon={faClone} />
              Wall
            </Link>
            <Link
              to="/mood-journal"
              aria-label="Mood journal"
              onClick={toggleSideMenu}
            >
              <FontAwesomeIcon icon={faHeartbeat} />
              Mood journal
            </Link>
            <Link
              to="/challenges"
              aria-label="Challenges"
              onClick={toggleSideMenu}
            >
              <FontAwesomeIcon icon={faBurst} />
              Challenges
              {notifications.openChallenges > 0 && (
                <div className="notifications-counter">
                  {notifications.openChallenges}
                </div>
              )}
            </Link>
            <Link
              to="/notifications"
              aria-label="Notifications"
              onClick={toggleSideMenu}
            >
              <FontAwesomeIcon icon={faBell} />
              Notifications
              {notifications.unreadNotifications > 0 && (
                <div className="notifications-counter">
                  {notifications.unreadNotifications}
                </div>
              )}
            </Link>
            <Link to="/chats" aria-label="Chats" onClick={toggleSideMenu}>
              <FontAwesomeIcon icon={faMessage} />
              Chats
              {notifications.unreadMessages > 0 && (
                <div className="notifications-counter">
                  {notifications.unreadMessages}
                </div>
              )}
            </Link>
            <Link to="/settings" aria-label="Settings" onClick={toggleSideMenu}>
              <FontAwesomeIcon icon={faGear} />
              Settings
            </Link>
            <SignOut
              setIsAuthenticated={setIsAuthenticated}
              icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            />
          </div>
        </>
      ) : (
        // Render a side menu prompting to sign in or sign up for unauthenticated users
        <div className="menu-links unauthenticated">
          <Link to="/signin" aria-label="Sign In" onClick={toggleSideMenu}>
            <FontAwesomeIcon icon={faRightToBracket} />
            Sign In
          </Link>
          <Link to="/signup" aria-label="Sign Up" onClick={toggleSideMenu}>
            <FontAwesomeIcon icon={faUserPlus} />
            Sign Up
          </Link>
        </div>
      )}
    </aside>
  );
};

export default SideMenu;
