// src/components/EmailChangeConfirm.js

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";

const EmailChangeConfirm = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("Processing your request...");
  const [error, setError] = useState("");
  const axiosInstance = useContext(AxiosContext);

  useEffect(() => {
    confirmEmailChange();
  }, []);

  const confirmEmailChange = async () => {
    try {
      const response = await axiosInstance.get(`/email-change-confirm/${token}/`);
      setMessage("Your email change has been confirmed. It will be updated after the waiting period.");
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message || "Failed to confirm email change.");
      } else {
        setError("An error occurred.");
      }
    }
  };

  return (
    <div className="main-content">
      <div className="email-change-confirm-container">
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="success-message">{message}</div>
        )}
      </div>
    </div>
  );
};

export default EmailChangeConfirm;