import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ClosableOverlay = ({
  onClose,
  headerContent,
  children,
  className,
  contentRef,
}) => {
  const handleBackdropClick = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  const header =
    typeof headerContent === "string" ? (
      <div className="overlay-title">{headerContent}</div>
    ) : (
      headerContent
    );

  return (
    <div className="overlay-backdrop" onClick={handleBackdropClick}>
      <div className={`closable-overlay show-overlay ${className || ""}`}>
        <div className="overlay-header">
          {header}
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="overlay-content" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

ClosableOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  headerContent: PropTypes.node, // Optional header content
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ClosableOverlay;
