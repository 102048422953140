// Feed.js

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Post from "../components/Post";
import { useDoodleContext } from "../components/DoodleContext";
import Loader from "../components/Loader";
import UserSuggestions from "../components/UserSuggestions/UserSuggestions";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import { useUserContext } from "../components/UserContext";
import { AxiosContext } from "../contexts/AxiosContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const Feed = ({
  handleOpenDoodleEditor,
  onFollowChange,
  isViewingOwnWall,
  isScrolledDown,
}) => {
  // Get username from route
  const { pathUsername } = useParams();

  const { doodles, setDoodles } = useDoodleContext();

  const { userName, userFollows } = useUserContext();

  const [hasNewDoodles, setHasNewDoodles] = useState(false);

  const axiosInstance = useContext(AxiosContext);

  // Use pagination hook
  const {
    items: paginatedDoodles,
    loading,
    error,
    loadMore,
    hasMore,
    setPage,
    setItems,
    page, // Destructure page to use in useEffect
  } = usePagination("/feed/", 10);

  // Setup intersection observer only after user interaction
  const [initialized, setInitialized] = useState(false);

  // State to track if a refresh is in progress
  const [refreshing, setRefreshing] = useState(false);

  // Update the shared doodles state whenever paginatedDoodles changes
  useEffect(() => {
    setDoodles(paginatedDoodles);
  }, [paginatedDoodles, setDoodles]);

  useEffect(() => {
    const handleScroll = () => {
      setInitialized(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch doodles for rendering on the Wall
  const fetchDoodles = () => {
    loadMore().then(() => {
      setInitialized(false); // Reset initialized state after successful page load
    });
  };

  // Fetch initial doodles when component mounts
  useEffect(() => {
    fetchDoodles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Fetch doodles only once when component mounts

  // Setup intersection observer
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "100px",
  });

  // Load more doodles when inView becomes true
  useEffect(() => {
    if (inView && hasMore && !loading && initialized && !refreshing) {
      fetchDoodles(); // Call fetchDoodles instead of loadMore directly
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasMore, loading, initialized, refreshing]);

  // Periodic new posts update checks
  useEffect(() => {
    // Function to fetch updates related to the feed
    const fetchFeedUpdates = async () => {
      if (doodles.length === 0) return; // No doodles to reference

      // Determine the ID to send to the backend
      const latestDoodle = doodles[0]; // Get the latest doodle
      const latestDoodleId = latestDoodle.repost_id || latestDoodle.id; // Prefer repost_id if available

      try {
        const response = await axiosInstance.get("/check-new-posts/", {
          params: { latest_doodle_id: latestDoodleId },
        });

        const hasNewDoodles = response.data.has_new_doodles;

        if (hasNewDoodles) {
          setHasNewDoodles(true);
        }
      } catch (error) {
        console.error("Failed to fetch feed updates", error);
      }
    };

    // Set up interval to fetch updates every 2 minutes (120000 milliseconds)
    const interval = setInterval(fetchFeedUpdates, 120000);

    // Clean up interval when component unmounts
    return () => clearInterval(interval);
  }, [doodles, axiosInstance]);

  const refreshFeed = () => {
    setHasNewDoodles(false); // Hide the notification
    setPage(1); // Reset to first page
    setDoodles([]); // Reset doodles to trigger reload
    setItems([]); // Reset items
    setRefreshing(true); // Indicate that a refresh is in progress
  };

  // useEffect to handle the refreshing logic
  useEffect(() => {
    if (refreshing) {
      if (page === 1 && paginatedDoodles.length === 0) {
        // Only fetch if we have reset to the first page and items are empty
        fetchDoodles();
        setRefreshing(false); // Reset the refreshing flag after fetching
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing, page, paginatedDoodles]);

  return (
    <main className="main-content">
      {userFollows < 10 && <UserSuggestions onFollowChange={onFollowChange} />}
      {/* New Doodles Notification */}
      <div
        className={`new-doodles-notification ${
          hasNewDoodles ? "visible" : ""
        } ${isScrolledDown ? "scrolled-position" : "top-position"}`}
        onClick={refreshFeed}
      >
        <FontAwesomeIcon icon={faArrowUp} className="notification-icon" />
      </div>
      <div className="feed">
        {doodles.map((doodle) => (
          <Post
            key={doodle.id}
            doodle={doodle}
            typeOfDoodle={"doodles"}
            loggedInUserName={userName}
            pathUsername={pathUsername}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            onFollowChange={onFollowChange}
            isViewingOwnWall={isViewingOwnWall} // In this case the unreposted post will be instantly removed from feed, which is what we want in this case
          />
        ))}
        {loading && <Loader />}
        {error && <p>Error loading feed</p>}
        {hasMore && <div ref={ref} />}{" "}
        {/* Intersection observer target element */}
      </div>
    </main>
  );
};

export default Feed;
